<div class="entertainment-component">
  <div class="row justify-content-center">
    <div class="heading text-center">
      <img src="/assets/img/version2/entertainment-heading-ribbon.png" />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-3 col-6">
      <img src="/assets/img/version2/entertainment-girl.png" />
    </div>
    <div class="col-md-3 col-6 isMobile">
      <img src="/assets/img/version2/entertainment-boy.png" />
    </div>

    <div class="col-md-6 max-width-40">
      <p>
        Introducing our fun and engaging incentive scheme designed specifically
        for kids! By turning tasks and goals into exciting challenges, children
        can earn points and watch their accomplishments grow. These points are
        not only a measure of their achievements, but can also be exchanged for
        exciting rewards! .Each point translates into vouchers or gifts which
        can be used at a range of vibrant entertainment venues. From thrilling
        theme parks to educational museums, our rewards offer something for
        everyone. With our incentive scheme, children are not just learning and
        growing, but also getting closer to their next adventure!
      </p>
    </div>
    <div class="col-md-3 isDesktop">
      <img src="/assets/img/version2/entertainment-boy.png" />
    </div>
  </div>

  <div class="row justify-content-center join-us-label">
    JOIN US, AND MAKE EVERYDAY TASKS FEEL LIKE A GAME.
  </div>
  <div class="row">
    <div class="sign-btn justify-content-center">
      <button class="btn" routerLink="/register">SIGN IN</button>
    </div>
  </div>
</div>
