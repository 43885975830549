import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHanlderService implements ErrorHandler {
  constructor(public injector: Injector) {}

  public handleError(error: Error | HttpErrorResponse): void {
    console.error('handleError', error);
    const notificationService = this.injector.get(NotificationService);
    const errorMessage: string = 'An error has occurred; Please try again.';

    let errorDetail: string = '';
    if (error instanceof HttpErrorResponse) {
      // hide spinner
      notificationService.showSpinner.next(false);
      // server side error
      if (error.status != null && error.status != undefined) {
        if (error.status === 0) {
          errorDetail = `Error message: System Not Available`;
        } else {
          if (error.error && error.status === 401) {
            errorDetail = '401. Authentication Failed. Please login.';
          } else if (error.error && error.status === 403) {
            errorDetail =
              '403. Authorization Failed. <br/> You do not have permissions to access resource.';
          } else if (error.error && error.status === 500) {
            errorDetail = `${this.parseErrorMessage(error)}`;
          } else if (error.error && error.status === 400) {
            errorDetail = `${this.parseBadRequest(error)}`;
          } else if (error.error && error.status === 404) {
            errorDetail = `Status Code: 404; Not Found`;
          }
        }
      }
    } else {
      // client side error
      errorDetail = error.message ? error.message : '';
    }

    let errorText: string;
    if (errorDetail.includes('Error details unavailable.')) {
      errorText = `${errorMessage}`;
    } else {
      errorText = `${errorDetail}`;
    }
    notificationService.showErrorMessage(errorText, 'Error');
  }

  private parseErrorMessage(err: HttpErrorResponse): string {
    try {
      if (err.error != null && err.error != undefined) {
        let message: string = `Id: ${err.error.cdiEventId}<br/>${err.error.message}`;
        return message;
      } else {
        return 'Error details unavailable.';
      }
    } catch (error) {
      return 'unavailable.';
    }
  }

  private parseBadRequest(err: any): string {
    let errorMessage: string = 'Validation Error';
    try {
      if (err.error != null && err.error != undefined) {
        if (
          err?.error?.title?.includes(
            'One or more validation errors occurred',
            0
          )
        ) {
          if (err?.error?.errors) {
            errorMessage = `Validation error occurred for request ${
              err?.url
            } <br/> Error Detail: <br/> ${JSON.stringify(err?.error?.errors)}`;
          }
        }
      }
    } catch (error) {
      return 'unavailable.';
    }
    return errorMessage;
  }
}
