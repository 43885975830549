<div class="subscription-component">
  <img class="cloud-1" src="/assets/img/version2/cloud-1.png" />
  <img class="cloud-3" src="/assets/img/version2/cloud-9.png" />
  <img class="cloud-4" src="/assets/img/version2/white-clouds.png" />
  <div class="col-md-5 col-12 cloud">
    <img src="/assets/img/version2/subscription-cloud-content.svg" />
  </div>
  <div class="col-md-7 col-12 cards">
    <ng-container *ngFor="let item of subscriptionPlans; let i = index">
      <div class="card-item">
        <div class="heading">{{ item.description }}</div>
        <div class="image">
          <img
            src="{{
              i % 2 === 0
                ? '/assets/img/version2/subscription-1.png'
                : '/assets/img/version2/subscription-2.png'
            }}"
          />
        </div>
        <div class="description">1 {{ item.planType }}</div>
        <div class="sign-btn">
          <button routerLink="/register">SIGN IN</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
