<div class="app-component" [ngClass]="{ 'coming-soon': isComingSoon }">
  <app-spinner></app-spinner>
  <header class="row">
    <app-navigation class="col"></app-navigation>
  </header>
  <main class="row">
    <router-outlet></router-outlet>
  </main>
</div>
<footer class="row">
  <app-footer class="col padding"> </app-footer>
</footer>
