<div class="register">
  <div class="row">
    <div class="col-12">
      <div class="heading text-center">PARENT INFO</div>
    </div>
  </div>
  <div class="wrapper stepper-options">
    <ol class="c-stepper">
      <li class="c-stepper__item" [class.active]="isStepOne">
        <h3 class="c-stepper__title">Step 1</h3>
      </li>
      <li class="c-stepper__item" [class.active]="!isStepOne">
        <h3 class="c-stepper__title">Step 2</h3>
      </li>
    </ol>
  </div>
  <div *ngIf="isStepOne">
    <form [formGroup]="stepOneInput" (submit)="goToStepTwo()">
      <img class="plant" src="/assets/img/version2/plant.png" />
      <div class="row">
        <div class="col-sm-2">
          <label for="firstName">First Name: </label>
        </div>
        <div class="col-sm-10">
          <input
            class="text_field"
            id="firstName"
            #firstName
            type="text"
            formControlName="firstName"
            pInputText
            (keyup)="onFirstNameKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('firstName')?.valid &&
              stepOneInput.get('firstName')?.dirty
            "
            >Please enter your first name.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="lastName">Last Name: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="lastName"
            #lastName
            type="text"
            formControlName="lastName"
            pInputText
            (keyup)="onLastNameKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('lastName')?.valid &&
              stepOneInput.get('lastName')?.dirty
            "
            >Please enter your last name.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="email">Email: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="email"
            #email
            type="text"
            formControlName="email"
            pInputText
            (keyup)="onEmailKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('email')?.valid &&
              stepOneInput.get('email')?.dirty
            "
            >Please enter a valid email address.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="country">Country: </label>
        </div>
        <div class="col-sm-10">
          <select
            (change)="onCountryChange($any($event.target).value)"
            formControlName="country"
          >
            <option value="">Choose your country</option>
            <option *ngFor="let country of countries" value="{{ country.id }}">
              {{ country.fullName }}
            </option>
          </select>
          <small
            *ngIf="
              !stepOneInput.get('country')?.valid &&
              stepOneInput.get('country')?.dirty &&
              stepOneInput.get('country')?.value != ''
            "
            >Please select a Country.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="address1">Address 1: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="address1"
            #address1
            type="text"
            formControlName="address1"
            pInputText
            (keyup)="onAddress1KeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('address1')?.valid &&
              stepOneInput.get('address1')?.dirty
            "
            >Please enter a valid address.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="address2">Address 2: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="address2"
            #address2
            type="text"
            formControlName="address2"
            pInputText
            (keyup)="onAddress2KeyUp($any($event.target).value)"
          />
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          stepOneInput.get('address1')?.value != '' &&
          stepOneInput.get('address2')?.value != ''
        "
      >
        <div class="col-sm-2">
          <label for="address3">Address 3: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="address3"
            #address3
            type="text"
            formControlName="address3"
            pInputText
            (keyup)="onAddress3KeyUp($any($event.target).value)"
          />
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          stepOneInput.get('address1')?.value != '' &&
          stepOneInput.get('address2')?.value != '' &&
          stepOneInput.get('address3')?.value != ''
        "
      >
        <div class="col-sm-2">
          <label for="address4">Address 4: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="address4"
            #address4
            type="text"
            formControlName="address4"
            pInputText
            (keyup)="onAddress4KeyUp($any($event.target).value)"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="city"
            >City{{
              this.stepOneInput.get("country")?.value == 5 ? "/Emirate" : ""
            }}:
          </label>
        </div>
        <div class="col-sm-10">
          <input
            id="city"
            #city
            type="text"
            formControlName="city"
            pInputText
            (keyup)="onCityKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('city')?.valid &&
              stepOneInput.get('city')?.dirty
            "
            >Please enter a valid city.</small
          >
        </div>
      </div>

      <div class="row" *ngIf="this.stepOneInput.get('country')?.value != '5'">
        <div class="col-sm-2">
          <label for="postalCode">Postal Code: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="postalCode"
            #postalCode
            type="text"
            formControlName="postalCode"
            pInputText
            (keyup)="onPostalCodeKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepOneInput.get('postalCode')?.valid &&
              stepOneInput.get('postalCode')?.dirty
            "
            >Please enter a valid Postal Code.</small
          >
        </div>
      </div>

      <div class="text-center flex button-section">
        <button label="Cancel" type="button" (click)="cancelAddUser()">
          Cancel
        </button>
        <button label="Add User" type="Submit" [disabled]="!stepOneInput.valid">
          Continue
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="!isStepOne">
    <form [formGroup]="stepTwoInput" (submit)="addUser()">
      <div class="row">
        <div class="col-sm-2">
          <label for="username">Username: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="username"
            #username
            required
            type="text"
            formControlName="username"
            pInputText
            (keyup)="onUsernameKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepTwoInput.get('username')?.valid &&
              stepTwoInput.get('username')?.dirty
            "
            >Please enter a username (it must be at least 5 characters).</small
          >
          <small *ngIf="usedUsername"
            >Username already exixts, please use different one.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="password">Password: </label>
        </div>
        <div class="col-sm-10">
          <div class="position-relative">
            <input
              id="password"
              #password
              required
              [type]="passwordType"
              formControlName="password"
              pInputText
              (keyup)="onPasswordKeyUp($any($event.target).value)"
            />
            <i
              (click)="togglePasswordVisibility()"
              [class.fa-eye]="passwordType === 'password'"
              [class.fa-eye-slash]="passwordType === 'text'"
              class="fa position-absolute iconPosition"
            ></i>
          </div>
          <small
            *ngIf="
              !stepTwoInput.get('password')?.valid &&
              stepTwoInput.get('password')?.dirty
            "
            >Please enter your password. It needs to have at least one lower
            case letter, one upper case letter, one number, and one special
            character.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2">
          <label for="confirmPassword">Confirm Password: </label>
        </div>
        <div class="col-sm-10">
          <input
            id="confirmPassword"
            #confirmPassword
            type="password"
            formControlName="confirmPassword"
            pInputText
            (keyup)="onConfirmPasswordKeyUp($any($event.target).value)"
          />
          <small
            *ngIf="
              !stepTwoInput.get('confirmPassword')?.valid &&
              stepTwoInput.get('confirmPassword')?.dirty
            "
            >This does not match your previous password.</small
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-2"></div>
        <div class="col-sm-10 checkbox-row">
          <input
            type="checkbox"
            formControlName="termsAndConditions"
            style="width: auto"
            (change)="onTermsAndConditionsChange($any($event.target).checked)"
          />
          <a
            ><label
              for="termsAndConditions"
              style="width: auto; padding-left: 8px"
              >Terms & Conditions</label
            ></a
          >
        </div>
        <small *ngIf="usedEmail"
          >Email is already taken, please try a different email.</small
        >
      </div>

      <div class="row">
        <div class="col-sm-12 button-section">
          <button
            label="Back"
            class="btn btn-danger"
            type="button"
            (click)="goToStepOne()"
          >
            Back
          </button>
          <button
            class="btn btn-primary"
            label="Add User"
            type="Submit"
            [disabled]="!stepTwoInput.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #successfullRegistration let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Parent Registered</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="gotToHome()"
    ></button>
  </div>
  <div class="modal-body">
    <p>Congratulations , Account created successfully.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="gotToParentPortal()">
      Go To Parent Portal
    </button>
  </div>
</ng-template>

<div
  class="modal fade"
  id="termsAndConditionsModal"
  #termsAndConditionsModal
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          Terms and Conditions
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="acceptTermsAndConditions(false)"
        ></button>
      </div>
      <div class="modal-body">
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum Terms and conditions loreum ipsum
        Terms and conditions loreum ipsum
      </div>
      <div class="modal-footer">
        <div class="to-right flex">
          <button
            label="Close"
            class="btn btn-danger"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="acceptTermsAndConditions(false)"
          >
            Close
          </button>
          <button
            label="Accept"
            class="btn btn-primary"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="acceptTermsAndConditions(true)"
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
