import { default as data } from '../../package.json';

export const environment = {
  appVersion: data.version,
  production: false,
  apiUrl: 'https://api.kidsgate.org',
  authDomain: 'login-kidgates-dev01.us.auth0.com',
  authAudience: 'https://api-dev.kidgates.co',
  authClientId: '7ra5bjPLUC2DvbhvpoxhBVh86MusXqIJ',
  authScope: 'openid profile create read update delete',
  parentsPortalLoginUrl: 'https://parents.kidsgate.org',
  kidsPortalLoginUrl: 'https://kids.kidsgate.org',
  homeUrl: 'https://www.kidsgate.org',
  recaptcha: {
    siteKey: '6Lc09vAmAAAAACNJ0Z6wZHemkjHeQtpydZguOpKL',
  },
};
