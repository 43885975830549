<div class="game-component">
  <div class="row justify-content-center">
    <div class="heading text-center">
      EQUIP YOUR CHILD FOR LIFE’S JOURNEY WITH OUR ENGAGING LIFE SKILLS GAMES
    </div>
  </div>

  <div class="media-div">
    <div class="first-column">
      <div class="who-we-are-image">
        <img src="/assets/img/version2/game-1.png" />
      </div>
    </div>
    <div class="second-column">
      <div class="who-we-are-image">
        <img src="/assets/img/version2/game-2.png" />
      </div>
    </div>
  </div>

 <p>
    In an increasingly complex world, mastering life skills is an essential as
    academic learning. Our platform offers a rich array of games specifically
    designed to teach vital skills like healthy food, problem solving, focus,
    critical thinking, financial literacy, communication, empathy and
    resilience. These games provide a safe and enjoyable environment for your
    child to practice and understand real-life scenarios. Our approach h allows
    children to learn at their own pace, applying lessons from virtual world to
    their everyday lives. By making life skills learning fun and interactive, we
    prepare your child for future challenges, make informed decisions, and
    ultimately thrive in their personal and professional lives.
  </p>

  <p class="fw-bold">
    Join us in empowering your child to become a confident, compassionate, and
    responsible individual.
  </p>

  <div class="sign-btn">
    <button class="btn" routerLink="/register">SIGN IN</button>
  </div>
</div>
