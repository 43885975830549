import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Country } from './models/country.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { AddParentUser } from './models/addParentUser.model';
import { environment } from 'src/environments/environment';
// import * as bootstrap from 'bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Email } from './models/email.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public stepOneInput: FormGroup;
  public stepTwoInput: FormGroup;
  public isStepOne: boolean;
  passwordType: string = 'password';
  public countries: Country[];
  public selectedCountry: Country;
  // captchaLoaded: boolean = false;
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public type!: 'image' | 'audio';
  @ViewChild('successfullRegistration', { static: true })
  regsiterationSuccessModal!: TemplateRef<any>;
  public usedEmail: boolean;
  public usedUsername: boolean = false;

  public reCAPTCHAToken: string = '';
  public tokenVisible: boolean = false;

  @ViewChild('termsAndConditionsModal', { read: ElementRef })
  modalElement!: ElementRef;
  constructor(
    public dataService: DataService,
    public notification: NotificationService,
    private modalService: NgbModal,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.isStepOne = true;
    this.countries = [];
    this.selectedCountry = new Country();
    this.usedEmail = false;

    this.stepOneInput = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      address3: new FormControl(''),
      address4: new FormControl(''),
      city: new FormControl('', [Validators.required]),
      postalCode: new FormControl('', [Validators.required]),
    });

    this.stepTwoInput = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=[^!@#$%^&*()?]*[!@#$%^&*()?]).{8,30}'
        ),
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.matchValidator('password'),
      ]),
      termsAndConditions: new FormControl('', [Validators.requiredTrue]),
      recaptcha: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.dataService
      .getAll<Country>('api/www/Register/GetAllCountries')
      .subscribe((countries) => {
        this.countries = countries;
        this.notification.showSpinner.next(false);
      });

    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        this.tokenVisible = true;
        this.reCAPTCHAToken = `Token [${token}] generated`;
      });
  }

  public errorMessage: any = '';
  public addUser(): void {
    this.recaptchaV3Service
      .execute('importantAction')
      .subscribe((token: string) => {
        this.tokenVisible = true;
        this.reCAPTCHAToken = `Token [${token}] generated`;

        //If it makes is past the reCaptcha v3
        if (token?.length > 0) {
          let newUser = new AddParentUser();
          newUser.username = this.stepTwoInput.get('username')?.value;
          newUser.password = this.stepTwoInput.get('password')?.value;
          newUser.firstName = this.stepOneInput.get('firstName')?.value;
          newUser.lastName = this.stepOneInput.get('lastName')?.value;
          newUser.email = this.stepOneInput.get('email')?.value;
          newUser.address1 = this.stepOneInput.get('address1')?.value;
          newUser.address2 = this.stepOneInput.get('address2')?.value;
          newUser.address3 = this.stepOneInput.get('address3')?.value;
          newUser.address4 = this.stepOneInput.get('address4')?.value;
          newUser.city = this.stepOneInput.get('city')?.value;
          newUser.postalCode = this.stepOneInput.get('postalCode')?.value;
          newUser.countryId = this.stepOneInput.get('country')?.value;
          newUser.portalName = 'Parents';
          this.usedUsername = false;
          this.dataService
            .add<AddParentUser>('api/www/Register/AddParentPortaluser', newUser)
            .subscribe((res: any) => {
              this.notification.showSpinner.next(false);
              if (res && res.isSuccessful == true) {
                this.openRegisterationSuccess(this.regsiterationSuccessModal);
              } else {
                let error = JSON.parse(res.errorMessage);
                this.notification.showErrorMessage(error.message, 'Error');
              }
            });
        }
      });
  }

  public goToStepTwo(): void {
    this.dataService
      .getById<Email>(
        `api/www/Register/TestEmail/${this.stepOneInput.get('email')?.value}`,
        ''
      )
      .subscribe((emailIsUsed: Email) => {
        this.notification.showSpinner.next(false);
        if (emailIsUsed.email == null) {
          this.usedEmail = false;
          this.isStepOne = false;
        } else {
          this.usedEmail = true;
        }
      });
  }

  public goToStepOne(): void {
    this.isStepOne = true;
  }

  //Used to match password with confirm password text inputs
  public matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo];
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control.parent?.controls as any)[matchTo].value
        ? null
        : { matching: true };
    };
  }
  private updateValidation(countryId: string) {
    const countryControl = this.stepOneInput.get('address1');
    const postalCodeControl = this.stepOneInput.get('postalCode');

    if (countryId === '5') {
      //5 is a UAE ID
      countryControl?.setValidators(null);
      postalCodeControl?.setValidators(null);
      postalCodeControl?.setValue('');
    } else {
      countryControl?.setValidators([Validators.required]);
      postalCodeControl?.setValidators([Validators.required]);
    }
    countryControl?.updateValueAndValidity();
    postalCodeControl?.updateValueAndValidity();
  }
  public cancelAddUser(): void {
    this.stepOneInput.reset();
    this.stepTwoInput.reset();
    window.location.href = environment.homeUrl;
  }

  public onFirstNameKeyUp(args: any): void {
    this.stepOneInput.get('firstName')?.setValue(args);
  }

  public onLastNameKeyUp(args: any): void {
    this.stepOneInput.get('lastName')?.setValue(args);
  }

  public onEmailKeyUp(args: any): void {
    this.stepOneInput.get('email')?.setValue(args);
    this.usedEmail = false;
  }

  public onCountryChange(args: any): void {
    this.stepOneInput.get('country')?.setValue(args);
    this.updateValidation(args);
  }

  public onAddress1KeyUp(args: any): void {
    this.stepOneInput.get('address1')?.setValue(args);
  }

  public onAddress2KeyUp(args: any): void {
    this.stepOneInput.get('address2')?.setValue(args);
  }

  public onAddress3KeyUp(args: any): void {
    this.stepOneInput.get('address3')?.setValue(args);
  }

  public onAddress4KeyUp(args: any): void {
    this.stepOneInput.get('address4')?.setValue(args);
  }

  public onCityKeyUp(args: any): void {
    this.stepOneInput.get('city')?.setValue(args);
  }

  public onPostalCodeKeyUp(args: any): void {
    this.stepOneInput.get('postalCode')?.setValue(args);
  }

  public onUsernameKeyUp(args: any): void {
    this.stepTwoInput.get('username')?.setValue(args);
    this.usedUsername = false;
  }

  public onPasswordKeyUp(args: any): void {
    this.stepTwoInput.get('password')?.setValue(args);
  }

  public onConfirmPasswordKeyUp(args: any): void {
    this.stepTwoInput.get('confirmPassword')?.setValue(args);
  }

  public onTermsAndConditionsChange(isAccept: boolean): void {
    this.stepTwoInput
      .get('termsAndConditions')
      ?.setValue(isAccept == true ? true : false);
  }

  openModal() {
    // const modal = new bootstrap.Modal(this.modalElement?.nativeElement);
    // modal.show();
  }

  public acceptTermsAndConditions(isAccept: boolean = false): void {
    this.stepTwoInput
      .get('termsAndConditions')
      ?.setValue(isAccept == true ? true : false);
  }
  // handleExpire(event: any) {
  //   // this.stepTwoInput.get('recaptcha')?.setValue('');
  // }
  // handleLoad(event: any) {
  //   // this.captchaLoaded = true;
  // }
  // handleSuccess(event: any) {
  //   // this.stepTwoInput.get('recaptcha')?.setValue(event);
  // }
  gotToParentPortal(): void {
    window.location.href = environment.parentsPortalLoginUrl;
  }
  gotToHome(): void {
    window.location.href = environment.homeUrl;
    this.modalService.dismissAll();
  }
  private openRegisterationSuccess(content: any): void {
    this.modalService.open(content);
  }
  togglePasswordVisibility(): void {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
  }
}
