import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './main/home/home.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { NutritionComponent } from './main/nutrition/nutrition.component';
import { ExerciseComponent } from './main/exercise/exercise.component';
import { WellnessComponent } from './main/wellness/wellness.component';
import { GamesComponent } from './main/games/games.component';
import { EntertainmentComponent } from './main/entertainment/entertainment.component';
import { SubscriptionComponent } from './main/subscription/subscription.component';
import { ComingSoonComponent } from './main/coming-soon/coming-soon.component';
import { RegisterComponent } from './header/register/register.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'nutrition',
    component: NutritionComponent,
  },
  {
    path: 'exercise',
    component: ExerciseComponent,
  },
  {
    path: 'wellness',
    component: WellnessComponent,
  },
  {
    path: 'games',
    component: GamesComponent,
  },
  {
    path: 'entertainment',
    component: EntertainmentComponent,
  },
  {
    path: 'subscription',
    component: SubscriptionComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
