export class AddParentUser {
  public portalName?: string;
  public username?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public address1?: string;
  public address2?: string;
  public address3?: string;
  public address4?: string;
  public city?: string;
  public postalCode?: string;
  public countryId?: number;
  public password?: string;
}
