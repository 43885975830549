import { Component, OnInit } from '@angular/core';
import { Subscription } from './model/subscription.model';
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  public subscriptionPlans?: Subscription[];
  constructor(
    public dataService: DataService,
    public notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.getSubscriptionWithOptions();
  }

  private getSubscriptionWithOptions(): void {
    this.dataService
      .getAll<Subscription>(
        'api/www/SubscriptionOptionMap/GetSubscriptionPlans'
      )
      .subscribe((subscriptions) => {
        this.notification.showSpinner.next(false);
        this.subscriptionPlans = subscriptions;
      });
  }
}
