import { SafeResourceUrl } from '@angular/platform-browser';

export class Exercise {
  public title?: string | null;
  public body?: string | null;
  public videoUrl?: SafeResourceUrl | null;
  constructor(title: string, body: string, videoUrl: SafeResourceUrl) {
    this.body = body;
    this.title = title;
    this.videoUrl = videoUrl;
  }
}
