import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(private toastr: ToastrService) {}

  public showSuccessMessage(message: string, title: string) {
    this.toastr.success(message, title, {
      timeOut: 5000,
      enableHtml: true,
    });
  }

  public showInfoMessage(message: string, title: string) {
    this.toastr.info(message, title, {
      timeOut: 5000,
      enableHtml: true,
    });
  }

  public showErrorMessage(message: string, title: string) {
    this.toastr.error(message, title, {
      disableTimeOut: true,
      closeButton: true,
      onActivateTick: true,
      enableHtml: true,
    });
  }

  public showWarningMessage(message: string, title: string) {
    this.toastr.warning(message, title, {
      timeOut: 5000,
      closeButton: true,
      onActivateTick: true,
      enableHtml: true,
    });
  }
  public removeAllNotifications(): void {
    this.toastr.clear();
  }
}
