<footer class="footer center">
  <img class="footer-cloud-1" src="/assets/img/version2/dark-cloud-2.png">
  <img class="footer-cloud-2" src="/assets/img/version2/dark-cloud-3.png">
  <div class="text-center p-2">
    <p>
      <a
        class="terms fw-semibold"
        data-bs-toggle="modal"
        data-bs-target="#exampleModalTermsOfUse"
        >Terms of Use</a
      >
      <a class="fw-semibold" data-bs-toggle="modal" data-bs-target="#exampleModalPrivacyPolicy">
        Privacy Policy</a
      >
    </p>
    <div class="copyright fw-semibold">Copyright &copy; {{ year }} - KidsGate</div>
    <!-- <button
      type="button"
      class="btn btn-primary support-btn"
      (click)="openSupportForm()"
    > 
    </button> -->
  </div>
</footer>
<div
  class="modal fade"
  id="exampleModalPrivacyPolicy"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Privacy Policy</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body"><app-privacy-policy></app-privacy-policy></div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="exampleModalTermsOfUse"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Terms Of Use</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body"><app-terms-of-use></app-terms-of-use></div>
    </div>
  </div>
</div>
