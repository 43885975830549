<div class="row navigation-header">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div class="logo-container">
        <a routerLink="/"
          ><img
            src="/assets/img/kid-gate-logo.png"
            alt="logo"
            class="img-fluid logo"
          />
        </a>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="toggleNavbar()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarNavDropdown"
        [ngClass]="{ show: isNavbarOpen }"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/home"
              routerLinkActive="active"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              (click)="toggleNavbar()"
              routerLink="/about-us"
              routerLinkActive="active"
              >About Us</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" type="button">Activites</a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a
                class="dropdown-item"
                routerLink="/nutrition"
                routerLinkActive="active"
                (click)="toggleNavbar()"
                >Nutrition</a
              >
              <a
                class="dropdown-item"
                routerLink="/games"
                routerLinkActive="active"
                (click)="toggleNavbar()"
                >Games</a
              >
              <a
                class="dropdown-item"
                routerLink="/exercise"
                routerLinkActive="active"
                (click)="toggleNavbar()"
                >Exercise</a
              >
              <a
                class="dropdown-item"
                routerLink="/wellness"
                routerLinkActive="active"
                (click)="toggleNavbar()"
                >Wellness</a
              >
              <a
                class="dropdown-item"
                routerLink="/entertainment"
                routerLinkActive="active"
                (click)="toggleNavbar()"
                >Entertainment</a
              >
            </div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              routerLink="/subscription"
              routerLinkActive="active"
              (click)="toggleNavbar()"
              ><span>Subscription</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              target="_blank"
              href="{{ parentPortalLoginUrl }}"
              ><span>Parent Portal</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              target="_blank"
              href="{{ kidsPortalLoginUrl }}"
              ><span>Kids Portal</span></a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              type="button"
              routerLink="/coming-soon"
              routerLinkActive="active"
              (click)="toggleNavbar()"
              ><span>Coming Soon</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
