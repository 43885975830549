<!-- <div class="home-component">
  <div class="row">
    <div class="col-lg-6 first-column">
      <div class="heading">
        Welcome To Kidsgate, Your Trusted Partner In Shaping Your Child’s Future
      </div>
      <p class="pt-4">
        Furthermore, our wellness support section ensures their emotional
        well-being is cared for, whilst self-development games stimulate their
        cognitive and social skills. The icing on the cake is an entertainment
        section, keeping them captivated while fostering creativity.
      </p>
      <p>
        Empowering your Child’s Growth and Joy, all in one place! JOIN US for a
        journey of comprehensive development, engaging activities, and wholesome
        entertainment.
      </p>
    </div>
    <div class="col-lg-6 second-column">
      <img
        src="/assets/img/banner1.png"
        alt="Random first slide"
        class="img-fluid"
      />
    </div>
  </div>
  <div class="row mt-4">
    <div class="header-btn">
      <a href="/register" class="theme-btn mt-2">SIGN IN</a>
    </div>
  </div>
</div> -->

<div id="home-component">
  <img class="cloud-1" src="/assets/img/version2/cloud-1.png" />
  <img class="cloud-2" src="/assets/img/version2/cloud-2.png" />
  <img class="cloud-12" src="/assets/img/version2/cloud-12.png" />
  <img class="bg-cloud-9" src="/assets/img/version2/cloud-9.png">
  <img class="home-cloud-1" src="/assets/img/version2/cloud-1.png" />

  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="home-component">
          <div class="row">
            <div class="col-lg-6 first-column">
              <div class="heading">
                Welcome To Kidsgate, Your Trusted Partner In Shaping Your
                Child’s Future
              </div>
              <p class="pt-4">
                Discover our innovative kid’s web application, designed to be
                the ultimate onestop solution for your child’s holistic
                development. Suitable for children ranging from preschool age to
                early teens. It offers meticulously crafted healthy meal plans
                to nurture their physical growth, along with fitness activities.
              </p>
            </div>
            <div class="col-lg-6 second-column">
              <img
                src="/assets/img/banner1.png"
                alt="Random first slide"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="header-btn">
              <a href="/register" class="theme-btn mt-2">SIGN IN</a>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="home-component">
          <div class="row">
            <div class="col-lg-6 first-column">
              <div class="heading">
                Welcome To Kidsgate, Your Trusted Partner In Shaping Your
                Child’s Future
              </div>
              <p class="pt-4">
                Furthermore, our wellness support section ensures their
                emotional well-being is cared for, whilst self-development games
                stimulate their cognitive and social skills. The icing on the
                cake is an entertainment section, keeping them captivated while
                fostering creativity.
              </p>
              <p>
                Empowering your Child’s Growth and Joy, all in one place! JOIN
                US for a journey of comprehensive development, engaging
                activities, and wholesome entertainment.
              </p>
            </div>
            <div class="col-lg-6 second-column">
              <img
                src="/assets/img/banner2.png"
                alt="Random first slide"
                class="img-fluid"
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="header-btn">
              <a href="/register" class="theme-btn mt-2">SIGN IN</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
