import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { DataService } from 'src/app/services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DtoZendeskResponse } from 'src/app/footer/models/DtoZendesk.model';
@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {
 
  @ViewChild('supportModal', { read: ElementRef })
  supportModalElement!: ElementRef;
  supportForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private notification: NotificationService,
    private toastr: ToastrService,
    private ngbModal:NgbModal
  ) { 
    this.supportForm = this.fb.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      UserName: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      Subject: ['', [Validators.required]],
      Body: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.supportForm.valid) {
      this.dataService
        .add<DtoZendeskResponse>(
          'api/www/Support/CreateTicket',
          this.supportForm.value
        )
        .subscribe((res) => {
          this.notification.showSpinner.next(false);
          this.ngbModal.dismissAll();
          if (res.isSuccessful == true) {
            this.toastr.success(
              'Ticket created successfully.Your Ticket ID: ' + res?.ticketId,
              'Support',
              {
                timeOut: 5000,
                enableHtml: true,
              }
            );
          } else {
            this.toastr.success(res.errorMessage, 'Creating Ticket Error', {
              timeOut: 10000,
              enableHtml: true,
            });
          }
        });
    }
  } 

  dismissModal(){
    this.ngbModal.dismissAll();
  }
}
