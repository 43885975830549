<div class="coming-soon-component">
  <div class="heading">COMING SOON</div>
 
  <div class="paragraph">
    <div class="p">
      Stay Tuned, Parents And Kids, Because The Next Big Thing In Kidsgate Is
      Just Around The Corner. Magic Awaits!" 🌟
    </div>
  </div>
</div>
