<div id="about-us-component">
  <img class="bg-about-us" src="/assets/img/version2/bg-about-us.png" />
  <img class="bg-white-clouds" src="/assets/img/version2/white-clouds.png" />
  <img class="bg-cloud-12" src="/assets/img/version2/cloud-12.png" />
  <img class="bg-cloud-9" src="/assets/img/version2/cloud-9.png" />

  <div id="carouselExampleIndicators" class="carousel slide">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="about-us">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="body">
                <div class="heading text-center">ABOUT US</div>
                <p class="card-text">
                  Our initiative sprang from the inspirational vision of Dr.
                  Mayada Alkhakany, a mother dedicated to enriching the lives of
                  her two children and others. Committed to fostering growth in
                  young people, she emphasizes the importance of essential life
                  skills alongside academic achievement. Facing adversity,
                  including her children's experiences with bullying, Dr.
                  Alkhakany persevered, maintaining her resolve to bolster not
                  only her children’s self-esteem but also to instill values of
                  cooperation, respect, and love in youth broadly. Her
                  unyielding support continues as her children progress through
                  college, reflecting her belief in the critical need for
                  children to develop holistic life skills as they navigate the
                  myriad opportunities life presents. Her journey epitomizes the
                  enduring impact of dedication and the nurturing of positive
                  values in the leaders of tomorrow.
                </p>
                <p class="fw-bold">
                  With KidsGate, you’re not just signing up for a platform, but
                  committing to a future where your child is equipped to succeed
                  in every walk of life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="vision">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="body">
                <div class="heading text-center">VISION</div>
                <p class="card-text">
                  Envisioning a secure and inspiring platform where kids ignite
                  curiosity and fuel their passions, while instilling wholesome
                  skills and attitudes. We cultivate core life values that set
                  the foundation for success across their life spectrum. It is
                  not just about succesding, it’s about thriving in every walk
                  of life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="mission">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="body">
                <div class="heading text-center">Mission</div>

                <p>
                  Skill-based approach offering unique opportunities to develop
                  vital life skills including financial literacy; communication
                  skills, and leadership.
                </p>
                <p>
                  Our platform serves as a bridge connecting acquired knowledge
                  with real-world experiences, enabling children to effectively
                  implement their learning in day-to-day activities.
                </p>
                <p>
                  We provide a rich mentorship environment, offering guidance,
                  support, and inspiration.
                </p>
                <p>
                  Harnessing the power of technology and innovation, we equip
                  kids with cutting-edge tools and resources that cultivate
                  future-ready skills.
                </p>
                <p>
                  With a philosophy rooted in positive reinforcement, we
                  motivate and recognize each milestone, celebrating
                  achievements while encouraging personal growth.
                </p>
                <p>
                  We emphasize personal development elements like mindfulness,
                  blending learning with enjoyment through interactive gaming
                  modules.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
