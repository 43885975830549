<div class="overlay" *ngIf="notification.showSpinner.value">
  <div class="center">
    <img
      src="../../../assets/img/loading.gif"
      alt="loading..."
      height="64"
      width="64"
    />
  </div>
</div>
