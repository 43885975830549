<div class="well-being-component">
  <div class="row justify-content-center">
    <div class="heading">WELL BEING</div>
  </div>
  <div class="row first-row">
    <div class="col-md-6 left-column">
      <div class="sub-title">
        Introduce Your Child To A Lifelong Journey Of Health And Happiness
      </div>
      <p>
        In a world where children are faced with increasing pressures and
        challenges, our platform stands as a proactive solution to equip them
        with resilience, positivity, and a healthy lifestyle. It is an
        all-encompassing resource that focuses on physical health, mental
        strength, emotional balance, and even social skills. Our platform is
        carefully designed to foster your child’s holistic growth, with
        activities engaging and enjoyable. By integrating elements like
        nutrition, physical activity, mindfulness, and socio-emotional learning,
        we are building a foundation for lifelong health and wellness..
      </p>
    </div>
    <div class="col-md-6 right-column">
      <img src="/assets/img/version2/wellness-1.png" />
    </div>
  </div>

  <div class="row second-row">
    <div class="col-md-6 left-column">
      <img width="100%" src="/assets/img/version2/yoga.png" />
    </div>
    <div class="col-md-6 right-column">
      <div class="sub-title fw-bold">
        Join our platform today and give your child the esstional tools for a
        happy, balanced, and fulfilling life.
      </div>
      <div class="row">
        <div class="sign-btn">
          <button class="btn" routerLink="/register">SIGN IN</button>
        </div>
      </div>
    </div>
  </div>
</div>
