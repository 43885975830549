import { Component } from '@angular/core';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  faUser = "faUser";
  isNavbarOpen = false;

  toggleNavbar() {
    this.isNavbarOpen = !this.isNavbarOpen;
  }
  public parentPortalLoginUrl: string = environment.parentsPortalLoginUrl;
  public kidsPortalLoginUrl: string = environment.kidsPortalLoginUrl;
}
