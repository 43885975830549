<div class="nutrition-component">
  <img class="nutriton-pg-1" src="/assets/img/version2/nutriton-pg-1.png" />
  <img class="cloud-1" src="/assets/img/version2/cloud-1.png" />
  <img class="nutriton-pg-2" src="/assets/img/version2/nutriton-pg-2.png" />
  <img class="nutriton-pg-3" src="/assets/img/version2/nutriton-pg-3.png" />
  <div class="row justify-content-center">
    <div class="heading text-center">
      Supercharge Your Child's Future The Power Of Optimal Nutrition
    </div>
  </div>
  <div class="row justify-content-center content-cloud-right-row">
    <div class="col-md-4 pt-5">
      <img style="width: 100%" src="/assets/img/version2/nutrition.png" />
    </div>
    <div class="col-md-8 cloud-11">
      <div class="cloud-container">
        <img
          src="/assets/img/version2/cloud-11.png"
          alt="Cloud"
          class="cloud-image1"
        />
        <div class="cloud-text">
          <p>
            Power up your child’s potential with optimal nutrition! By
            delivering a comprehensive mix of essential elements like proteins,
            carbohydrates, fats, vitamins and minerals, we promote healthy
            growth and development, laying the foundation for a future of
            strength and vitality.
          </p>
          <p>
            Boost immunity, banish disease a robust diet is our frontline
            defense, empowering your child’s immune system and protecting them
            from health threats and infections. Plus, by establishing wholesome
            eating habits early on, we mitigate the risk of chronic disesaes
            such as heart disease, diabetes and obesity in later life.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row content-cloud-left-row">
    <div class="col-md-7 cloud-22">
      <div class="cloud-container">
        <img
          src="/assets/img/version2/cloud-22.png"
          alt="Cloud"
          class="cloud-image2"
        />
        <div class="cloud-text bottom-cloud">
          <p>
            Nourishing the mind and body – the correct nutritional balance is
            key for advancing the perfect fuel for brain function, enhancing
            their ability to learn and concentrate.
          </p>
          <p>
            In essence, we echo UNICEF’s vision – championing a balanced,
            wholesome diet for children as the cornerstone of their growth,
            immune fortification, cognitive enhancement and overall well-being.
            Register your child today and let’s us support you to guide and
            encourage your child to acquire healthy skills.
          </p>
        </div>
      </div>
    </div>
    <div
      class="col-md-5 justify-content-center align-items-center display-flex"
    >
      <div class="sign-btn">
        <button class="btn" routerLink="/register">SIGN IN</button>
      </div>
    </div>
  </div>
</div>
