<div id="supportModal" #supportModal>

    <form (ngSubmit)="onSubmit()" [formGroup]="supportForm">
        <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">
                Support
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="dismissModal()"></button>
        </div>
        <div class="modal-body">

            <div class="row">
                <div class="col-sm-12">
                    <input id="FirstName" type="text" class="form-control" placeholder="Enter First Name"
                        formControlName="FirstName" pInputText />
                    <small class="p-error" *ngIf="
                supportForm.get('FirstName')?.invalid &&
                  supportForm.get('FirstName')?.touched
                ">
                        First Name is required
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <input id="LastName" type="text" class="form-control" placeholder="Enter Last Name"
                        formControlName="LastName" pInputText />
                    <small class="p-error" *ngIf="
                  supportForm.get('LastName')?.invalid &&
                    supportForm.get('LastName')?.touched
                  ">
                        Last Name is required
                    </small>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <input id="Email" type="text" class="form-control" placeholder="Enter Email" formControlName="Email"
                        pInputText />
                    <small class="p-error" *ngIf="
                  supportForm.get('Email')?.invalid &&
                    supportForm.get('Email')?.touched
                  ">
                        Email is required
                    </small>
                </div>
            </div>

            <div class="row">

                <div class="col-sm-12">
                    <input id="UserName" type="text" class="form-control" placeholder="Enter User Name"
                        formControlName="UserName" pInputText />
                    <small class="p-error" *ngIf="
                  supportForm.get('UserName')?.invalid &&
                    supportForm.get('UserName')?.touched
                  ">
                        UserName is required
                    </small>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <input id="Subject" type="text" class="form-control" placeholder="Enter Subject"
                        formControlName="Subject" pInputText />
                    <small class="p-error" *ngIf="
                  supportForm.get('Subject')?.invalid &&
                    supportForm.get('Subject')?.touched
                  ">
                        Subject is required
                    </small>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <textarea id="Body" type="text" class="form-control" placeholder="What can we help with"
                        formControlName="Body"></textarea>
                    <small class="p-error" *ngIf="
                  supportForm.get('Body')?.invalid &&
                    supportForm.get('Body')?.touched
                  ">
                        Details is required
                    </small>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button label="Submit" class="btn btn-primary" type="submit" [disabled]="!supportForm.valid">
                Submit
            </button>
        </div>
    </form>
</div>