import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './header/navigation/navigation.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { GlobalErrorHanlderService } from './services/global-error-hanlder.service';
import { HomeComponent } from './main/home/home.component';
import { AboutUsComponent } from './main/about-us/about-us.component';
import { NutritionComponent } from './main/nutrition/nutrition.component';
import { GamesComponent } from './main/games/games.component';
import { EntertainmentComponent } from './main/entertainment/entertainment.component';
import { SubscriptionComponent } from './main/subscription/subscription.component';
import { ComingSoonComponent } from './main/coming-soon/coming-soon.component';
import { ExerciseComponent } from './main/exercise/exercise.component';
import { WellnessComponent } from './main/wellness/wellness.component';
import { RegisterComponent } from './header/register/register.component';
import { SupportModalComponent } from './main/support-modal/support-modal.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavigationComponent,
    SpinnerComponent,
    HomeComponent,
    AboutUsComponent,
    NutritionComponent,
    WellnessComponent,
    GamesComponent,
    EntertainmentComponent,
    SubscriptionComponent,
    ComingSoonComponent,
    ExerciseComponent,
    WellnessComponent,
    RegisterComponent,
    SupportModalComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    RecaptchaV3Module,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHanlderService },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
