 

<div class="exercise-component">
  <div class="row justify-content-center">
    <div class="heading text-center">
      UNLOCK YOUR CHILD’S POTENTIAL WITH THE POWER OF MINDFUL MOVEMENT, BREATH
      CONTROL AND CENTERED RELAXATION.
    </div>
  </div>

  <div class="media-div">
    <iframe
      class="e2e-iframe-untrusted-src"
      title=""
      [src]="cardData[activeNavigationButtonIndex].videoUrl"
    ></iframe>
  </div>

  <p>
    In today’s fast-paced world, our children need tools to navigate stress,
    anxiety, and the demands of daily life. Our holistic platform combindes
    physical activity, yoga, and meditation to not only promote a healthy body
    but also to foster a sound mind. Yoga encourages flexibility, strength, and
    balance, while meditation cultivates focus, mindfulness, and emotional
    resilience. Our practices will equip your child with essential life skills,
    enhancing their concentration, self esteem, and ability to manage emotions.
    A calmer, more confident child leads to improved academic performance and
    healthier social interactions.Let’s work together to guide your child on a
    journey of self- discovery, growth, and balanced well-being.
  </p>

  <div class="sign-btn">
    <button class="btn" routerLink="/register">SIGN IN</button>
  </div>
</div>
