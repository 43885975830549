import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import {
//   faChevronLeft,
//   faChevronRight,
// } from '@fortawesome/free-solid-svg-icons';
import { Exercise } from './model/exercise.model';
@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss'],
})
export class ExerciseComponent {
  faChevronLeft = "faChevronLeft";
  faChevronRight = "faChevronRight";
  public navigationButtonClass: string[] = [];
  public isChevronDisabled: boolean[] = [];
  public activeNavigationButtonIndex: number = 1;
  public cardData: Exercise[] = [];
  public safeUrl1: SafeResourceUrl = '';
  public url1: string = '';
  public safeUrl2: SafeResourceUrl = '';
  public url2: string = '';
  public safeUrl3: SafeResourceUrl = '';
  public url3: string = '';

  constructor(private sanitizer: DomSanitizer) {
    for (let index = 0; index < 4; index++) {
      if (index == 1) {
        this.navigationButtonClass.push('btn btn-primary active');
      }
      this.navigationButtonClass.push('btn btn-primary');
    }
    for (let index = 0; index < 2; index++) {
      if (index == 0) {
        this.isChevronDisabled.push(true);
      }
      this.isChevronDisabled.push(false);
    }
    this.url1 = 'https://www.youtube.com/embed/oc4QS2USKmk';
    this.safeUrl1 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url1);
    this.url2 = 'https://www.youtube.com/embed/sM9tpg1CDOU';
    this.safeUrl2 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url2);
    this.url3 = 'https://www.youtube.com/embed/Jkd6L2IJ3kw';
    this.safeUrl3 = this.sanitizer.bypassSecurityTrustResourceUrl(this.url3);
    this.cardData.push(new Exercise('', '', ''));
    this.cardData.push(
      new Exercise(
        'Unlock your child’s potential with the power of mindful movement, breath control, and Centered Relaxation.',
        'In today’s fast-paced world, our children need tools to navigate stress, anxiety, and the demands of daily life. Our holistic platform combines physical activity, yoga, and meditation to not only promote a healthy body but also to foster a sound mind. Yoga encourages flexibility, strength, and balance, while meditation cultivates focus, mindfulness, and emotional resilience. Our practices will equip your child with essential life skills, enhancing their concentration, self-esteem, and ability to manage emotions. A calmer, more confident child leads to improved academic performance and healthier social interactions. Let’s work together to guide your child on a journey of self-discovery, growth, and balanced well-being.',
        this.safeUrl1
      )
    );
    this.cardData.push(
      new Exercise(
        '15-MIN FULL BODY KIDS WORKOUT',
        'It only takes 15 minutes to get the kids exercising to stay healthy! With this routine, kids will perform 12 different exercises to work on different parts of their bodies, generating a high burn of calories, which will get them to burn fat. Kids will also make their bodies stronger and ready for a healthy and more energetic growth. Lets call the little ones and start this new workout! 💪',
        this.safeUrl2
      )
    );
    this.cardData.push(
      new Exercise(
        'Kids Morning Workout - Kids Daily Exercises',
        'Its all about getting active and exercising everyday at home! Simple, quick moves for kids to help them build their muscles, strengthen their bones, improve flexibility and even burn calories! Lets begin the workout.These fun, dinamic and easy exercises will get the little ones moving, spending a lot of energy, which will make them lose a lot of calories, burning fat and losing weight. ',
        this.safeUrl3
      )
    );
  }
  currentPage: number = 1;
  public onClickNavigationButton(args: number): void {
    if (args === 0) {
      if (this.activeNavigationButtonIndex == 1) {
        this.isChevronDisabled[0] = true;
        this.isChevronDisabled[1] = false;
      } else if (this.activeNavigationButtonIndex == 2) {
        this.activeNavigationButtonIndex = 1;
        this.navigationButtonClass[1] = 'btn btn-primary active';
        this.navigationButtonClass[2] = 'btn btn-primary';
        this.navigationButtonClass[3] = 'btn btn-primary';
        this.isChevronDisabled[0] = true;
        this.isChevronDisabled[1] = false;
      } else if (this.activeNavigationButtonIndex == 3) {
        this.activeNavigationButtonIndex = 2;
        this.navigationButtonClass[2] = 'btn btn-primary active';
        this.navigationButtonClass[1] = 'btn btn-primary';
        this.navigationButtonClass[3] = 'btn btn-primary';
        this.isChevronDisabled[0] = false;
        this.isChevronDisabled[1] = false;
      }
    } else if (args === 1) {
      this.activeNavigationButtonIndex = 1;
      this.navigationButtonClass[1] = 'btn btn-primary active';
      this.navigationButtonClass[2] = 'btn btn-primary';
      this.navigationButtonClass[3] = 'btn btn-primary';
      this.isChevronDisabled[0] = true;
      this.isChevronDisabled[1] = false;
    } else if (args === 2) {
      this.activeNavigationButtonIndex = 2;
      this.navigationButtonClass[1] = 'btn btn-primary';
      this.navigationButtonClass[2] = 'btn btn-primary active';
      this.navigationButtonClass[3] = 'btn btn-primary';
      this.isChevronDisabled[0] = false;
      this.isChevronDisabled[1] = false;
    } else if (args === 3) {
      this.activeNavigationButtonIndex = 3;
      this.navigationButtonClass[1] = 'btn btn-primary';
      this.navigationButtonClass[2] = 'btn btn-primary';
      this.navigationButtonClass[3] = 'btn btn-primary active';
      this.isChevronDisabled[0] = false;
      this.isChevronDisabled[1] = true;
    } else if (args === 4) {
      if (this.activeNavigationButtonIndex == 1) {
        this.activeNavigationButtonIndex = 2;
        this.navigationButtonClass[2] = 'btn btn-primary active';
        this.navigationButtonClass[1] = 'btn btn-primary';
        this.navigationButtonClass[3] = 'btn btn-primary';
        this.isChevronDisabled[0] = false;
        this.isChevronDisabled[1] = false;
      } else if (this.activeNavigationButtonIndex == 2) {
        this.activeNavigationButtonIndex = 3;
        this.navigationButtonClass[1] = 'btn btn-primary';
        this.navigationButtonClass[2] = 'btn btn-primary';
        this.navigationButtonClass[3] = 'btn btn-primary active';
        this.isChevronDisabled[0] = false;
        this.isChevronDisabled[1] = true;
      } else if (this.activeNavigationButtonIndex == 3) {
        this.isChevronDisabled[0] = false;
        this.isChevronDisabled[1] = true;
      }
    }
  }
}
