import { Component } from '@angular/core';
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; 
import { SupportModalComponent } from '../main/support-modal/support-modal.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public faQuestionCircle="faQuestionCircle";
  public year: number;
  constructor(public ngbModal:NgbModal) {
    let date: Date = new Date();
    this.year = date.getFullYear();
  }

  openSupportForm(){
    const modalRef =   this.ngbModal.open(SupportModalComponent);
    console.log(modalRef);
  }
}
