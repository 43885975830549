import { Component } from '@angular/core';
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { GoogleAnalyticService } from 'src/app/services/google-analytic.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  faQuestionCircle = "";
  constructor() {}
}
